export const images = [
  {
    image: "/images/p1.jpg",
    location: "Guang Zhou",
    date: "10/28/2018",
    story:
      "Guangzhou, my hometown, is the city where I have lived for over 20 years. One evening as I rode across the Pearl River, I was deeply touched by the scene. The Pearl River marks Guangzhou's history of more than 2,000 years, while the Guangzhou Tower, Liede Bridge and Zhu Jiang Xin Cheng on the horizon symbolize the development and prosperity of Guangzhou. Without doubt, Guangzhou will always hold an important place in my heart.",
  },
  {
    image: "/images/p2.jpg",
    location: "San Francisco",
    date: "07/24/2018",
    story:
      "This photo was taken on Lambord Street, which is famous for a steep, one-block section with eight hairpin turns. Interestingly, the cluster of flowers between the two houses is in the shape of a heart. The two families might have reached an agreement to jointly care for and share these flowers. Perhaps they plant flowers not only for their own enjoyment, but also to convey beauty to passers-by.",
  },
  {
    image: "/images/p3.jpg",
    location: "Big Sur",
    date: "07/26/2018",
    story:
      "This photo was taken during my trip along California's Highway 1. The highway closely follows the rugged coastline, winding along steep cliffs. However, it's not just the breathtaking Pacific Ocean views that make this road special; there are also serene, picturesque paths like this one. The setting sun filters through the leaves, casting light on the road ahead, as if guiding travelers on their journey.",
  },
  {
    image: "/images/p4.jpg",
    location: "Los Angeles",
    date: "07/31/2018",
    story:
      "At the time I took this photo, I was heading towards the peak where the Hollywood Sign locates, hoping to capture the sunset there. However, I soon found myself stumbling on a muddy, narrow and steep hiking trail with only my Vans slip-ons and a disconnected, dying phone. Although I ended up hiking for four hours without fulfilling my goal, the journey turned out to be an extraordinary experience.",
  },
  {
    image: "/images/p5.jpg",
    location: "San Diego",
    date: "08/03/2018",
    story:
      "There are many people paragliding and sailing at La Jolla Shores. The person in front of me might be a lifeguard closely watching over everyone’s safety. But when he raised his camera, I felt he might simply be an ordinary visitor enjoying some leisure time. Of course, he could also be a lifeguard who lifted his camera out of passion for the view.",
  },
  {
    image: "/images/p6.jpg",
    location: "Baker",
    date: "08/04/2018",
    story:
      "My trip from San Diego to Las Vegas was full of vast deserts and canyons. When I pulled off at a gas station, it was so hot outside that I felt like the breath in my mouth was cold. I then understood why the place is named Baker. In this scorching and desolate place, time seemed to stop. This is just like the contents of the famouse painting, The Persistence of Memory, which depicts melting clocks in a desert.",
  },
  {
    image: "/images/p7.jpg",
    location: "New Haven",
    date: "08/09/2018",
    story:
      "The experience of capturing this photo was quite memorable. It was sunny when I set out for Lighthouse Point Park, but by the time I arrived, it was getting overcast and raining heavily. Luckily, the rain soon stopped and a double rainbow appeared on the beach. I was thrilled and kept filming until sunset. I got a lot of amazing photos featuring the day-to-night transition on the beach under varying weather conditions.",
  },
  {
    image: "/images/p8.jpg",
    location: "New York",
    date: "08/13/2018",
    story:
      "Central Park, with over two centuries of history, is a marvel in the concrete jungle of New York City. At the center of the photo are towering skyscrapers flanked by seeminly historic buildings on both sides. Everything including the thick, somber clouds is mirrored in this lake in Central Park. The lake is big, Central Park is bigger, but New York City is way bigger.",
  },
  {
    image: "/images/p9.jpg",
    location: "Columbus",
    date: "02/12/2020",
    story:
      "Columbus is where I began my student life in the U.S. During my first two months at the Ohio State University, I had to walk through snow to attend an 8 AM class before sunrise twice a week. My days were filled with classes and nights spent studying at Thompson Library, where this photo was taken. I went through so much—the pandemic, moving, learning to drive, buying and selling a car, graduation, and moving again. I will always cherish this chapter of my life.",
  },
  {
    image: "/images/p10.jpg",
    location: "New York",
    date: "01/25//2023",
    story:
      "When I visited Central Park in 2018, I never imagined that four years later, I would be studying at New York University. During my school days in New York, I didn't go anywhere but Washington Square Park. The park that is usually bustling became especially tranquil on cold, rainy nights, and it was during one of these moments that I captured a photo of the Washington Square Arch.",
  },
];
