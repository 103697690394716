import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div id="footer">
      <p id="copyright">
        Copyright © {new Date().getFullYear()} Eric Young. All rights reserved.
      </p>
      <div className="contact">
        <a
          href="https://github.com/EricYoung37"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa-brands fa-github" />
        </a>
        <a
          href="https://www.linkedin.com/in/ericyoung6"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa-brands fa-linkedin" />
        </a>
      </div>
    </div>
  );
}

export default Footer;
