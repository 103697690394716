import React, { useRef, useEffect } from "react";
import "./Home.css";
import Timeline from "../components/Timeline";
import { NavLink } from "react-router-dom";
import { headerRef } from "../components/MyNav";
import { motion } from "framer-motion";

function Home() {
  const heroRef = useRef(null);
  const aboutRef = useRef(null);

  const heroAnimationVariants = {
    initial: {
      opacity: 0,
    },
    animate: (delayFactor) => ({
      opacity: 1,
      transition: {
        duration: 0.3,
        delay: 0.4 * delayFactor,
      },
    }),
  };

  const useIntersectionObserver = (ref, callback) => {
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            const title = entry.target.id;
            if (title === "hero") {
              headerRef.current.innerHTML = "Hi there!";
            } else {
              headerRef.current.innerHTML = "About Me";
            }
          }
        },
        { threshold: 0.4 }
      );

      const { current } = ref;
      if (current) {
        observer.observe(current);
      }
      return () => observer.disconnect();
    }, [ref, callback]);
  };

  useIntersectionObserver(heroRef, headerRef);
  useIntersectionObserver(aboutRef, headerRef);

  return (
    <>
      <section id="hero" ref={heroRef}>
        <div id="waves">
          <div className="wave tape1"></div>
          <div className="wave tape2"></div>
          <div className="wave tape3"></div>
          <div className="wave tape4"></div>
        </div>
        <div id="hero-headers">
          <motion.h1
            variants={heroAnimationVariants}
            initial="initial"
            whileInView="animate"
            viewport={{ once: true }}
            custom={1}
          >
            I'm Eric Young
          </motion.h1>
          <motion.h3
            variants={heroAnimationVariants}
            initial="initial"
            whileInView="animate"
            viewport={{ once: true }}
            custom={2}
          >
            An Exintrovert
          </motion.h3>
        </div>
        <motion.div
          id="hero-button"
          variants={heroAnimationVariants}
          initial="initial"
          whileInView="animate"
          viewport={{ once: true }}
          custom={3}
        >
          <NavLink to="/#about">
            <span>About Me</span>
          </NavLink>
        </motion.div>
        <motion.p
          id="hero-comment"
          variants={heroAnimationVariants}
          initial="initial"
          whileInView="animate"
          viewport={{ once: true }}
          custom={4}
        >
          "Exintrovert" came to my mind when I thought about a word that could
          best describe my personality. It's the combination of "extrovert" and
          "introvert" with "ex" preceding "in". I choose this order because
          that's how we usually get to know a person, from the outside to the
          inside.
        </motion.p>
      </section>

      <section id="about" ref={aboutRef}>
        <div id="profile-grid">
          <div className="profile-item">
            <p id="introduction">
              I'm Eric Young. I'm currently looking for a software engineering
              position. While I hope to work with cloud-native architecture, I'm
              also interested in positions related to machine-learning,
              operating systems, and GPUs. I'm excited to learn and grow within
              a collaborative team, where I can leverage my skills to make a
              meaningful impact.
            </p>
          </div>
          <div className="profile-item">
            <div id="profile-img"></div>
          </div>
          <div id="timeline-wrapper" className="profile-item">
            <Timeline />
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
