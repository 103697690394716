import React, { useRef, useEffect, useState } from "react";
import "./Hobbies.css";
import { headerRef } from "../components/MyNav";
import Carousel from "../components/Carousel";

function Hobbies() {
  const phogRef = useRef(null);
  const soccerRef = useRef(null);
  const [playVid2, setPlayVid2] = useState(false);

  const useIntersectionObserver = (ref, callback) => {
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            const title = entry.target.id;
            headerRef.current.innerHTML =
              String(title[0]).toUpperCase() + String(title).slice(1);
          }
        },
        { threshold: 0.4 }
      );

      const { current } = ref;
      if (current) {
        observer.observe(current);
      }
      return () => observer.disconnect();
    }, [ref, callback]);
  };

  useIntersectionObserver(phogRef, headerRef);
  useIntersectionObserver(soccerRef, headerRef);

  return (
    <>
      <section id="photography" ref={phogRef}>
        <Carousel />
      </section>
      <section id="soccer" ref={soccerRef}>
        <div className="grid-wrapper">
          <div className="grid-item">
            <video src="/videos/soccer1.mp4" autoPlay playsInline muted loop />
          </div>

          <div className="grid-item">
            <div id="video-comment" className={playVid2 ? "show" : ""}>
              <p>
                Soccer used to be my only way to relax when I was in high
                school. I used to love it to the point of making a ball striking
                pose wherever I go, as this video shows. My feet has been
                seriously injured three times from playing soccer and haven't
                fully recovered to this day. However, what finally kept me away
                from soccer wasn't the injuries but the pandemic in 2020 and the
                academic workload that followed.
              </p>
            </div>

            <video
              src="/videos/soccer2.mp4"
              autoPlay
              playsInline
              muted
              loop
              onPlay={() => {
                setPlayVid2(true);
              }}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default Hobbies;
