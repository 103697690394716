import React, { useEffect, useState } from "react";
import "./Carousel.css";
import { images } from "./ImgData";
import { motion, useMotionValue } from "framer-motion";

const DRAG_THRESHOLD = 20; // px
const SPRING_CONFIG = {
  type: "spring",
  mass: 3,
  stiffness: 400,
  damping: 50,
}; // transition

const ONE_SECOND = 1000;
const AUTO_DELAY = ONE_SECOND * 12;

function Carousel() {
  const [imgIndex, setImgIndex] = useState(0);

  const dragX = useMotionValue(0);

  const overlayAnimationVariants = {
    initial: {
      opacity: 0,
      x: -64,
    },
    animate: (delayFactor) => ({
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.4,
        delay: 0.08 * delayFactor,
      },
    }),
  };

  // italicize target string in a piece of text
  const Italicizer = ({ text, target }) => {
    const idx = text.indexOf(target);
    const lng = target.length;
    if (idx !== -1) {
      const head = text.substring(0, idx);
      const tail = text.substring(idx + lng);
      return (
        <>
          {head}
          <i>{target}</i>
          {tail}
        </>
      );
    }
    return <>{text}</>;
  };

  const Images = ({ imgIndex }) => {
    return (
      <>
        {images.map((image, index) => {
          return (
            <motion.div
              key={index}
              className="carousel-card"
              style={{ backgroundImage: `url(${image.image})` }}
              // animate={{ scale: index === imgIndex ? 1 : 0.9 }}
              animate={{ scale: index === imgIndex ? 0.95 : 0.85 }}
              transition={SPRING_CONFIG}
            >
              <motion.div
                className="carousel-overlay"
                variants={overlayAnimationVariants}
                initial="initial"
                whileInView="animate"
                viewport={{ once: true }}
                custom={1}
              >
                <motion.h3
                  variants={overlayAnimationVariants}
                  initial="initial"
                  whileInView="animate"
                  viewport={{ once: true }}
                  custom={2}
                >
                  A Photo, A Story.
                </motion.h3>
                <motion.h1
                  variants={overlayAnimationVariants}
                  initial="initial"
                  whileInView="animate"
                  viewport={{ once: true }}
                  custom={3}
                >
                  {image.location.toUpperCase()}
                </motion.h1>
                <motion.p
                  variants={overlayAnimationVariants}
                  initial="initial"
                  whileInView="animate"
                  viewport={{ once: true }}
                  custom={4}
                >
                  <Italicizer
                    text={image.story}
                    target={"The Persistence of Memory"}
                  />
                </motion.p>
              </motion.div>
            </motion.div>
          );
        })}
      </>
    );
  };

  const onDragEnd = () => {
    const x = dragX.get();
    if (x <= -DRAG_THRESHOLD) {
      // go right
      setImgIndex((pv) => (pv + 1) % images.length);
    } else if (x >= DRAG_THRESHOLD) {
      // go left
      setImgIndex(
        (pv) => (((pv - 1) % images.length) + images.length) % images.length
      ); // modulo on -1 (% is the remainder operator)
    }
  };

  const Dots = ({ imgIndex, setImgIndex }) => {
    return (
      <div id="carousel-dots">
        {images.map((_, index) => {
          return (
            <button
              key={index}
              onClick={() => setImgIndex(index)}
              className={index === imgIndex ? "selected" : ""}
            />
          );
        })}
      </div>
    );
  };

  // auto play
  useEffect(() => {
    const intervalRef = setInterval(() => {
      const x = dragX.get();

      if (x === 0) {
        setImgIndex((pv) => {
          if (pv === images.length - 1) {
            return 0;
          }
          return pv + 1;
        });
      }
    }, AUTO_DELAY);
    return () => clearInterval(intervalRef);
  }, [dragX]);

  // const GradientEdges = () => {
  //   return (
  //     <>
  //       <div className="left-edge" />
  //       <div className="right-edge" />
  //     </>
  //   );
  // };

  return (
    <div id="carousel">
      <motion.div
        id="carousel-wrapper"
        drag="x"
        dragConstraints={{ left: 0, right: 0 }}
        transition={SPRING_CONFIG}
        animate={{ translateX: `-${imgIndex * 100}%` }}
        onDragEnd={onDragEnd}
        style={{ x: dragX }}
      >
        <Images imgIndex={imgIndex} />
      </motion.div>
      <Dots imgIndex={imgIndex} setImgIndex={setImgIndex}></Dots>
      {/* <GradientEdges /> */}
    </div>
  );
}

export default Carousel;
