import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Dropdown.css";

function Dropdown({ setDropdown }) {
  const MenuItems = [
    {
      title: "Photography",
      className: "dropdown-link",
    },
    {
      title: "Soccer",
      className: "dropdown-link",
    },
  ];
  const [click, setClick] = useState(false);

  return (
    // a new random key is created every time the dropdown menu is
    // re-rendered, so React treates the element as a different one
    // thus roll-out animation triggered for the dropdown menu
    <ul
      key={Math.random()}
      onClick={() => setClick(!click)}
      className={click ? "dropdown-menu clicked" : "dropdown-menu"}
    >
      {MenuItems.map((item, index) => {
        return (
          <li key={index}>
            <NavLink
              className={item.className}
              to={"/hobbies#" + item.title.toLocaleLowerCase()}
              onClick={() => setDropdown(false)}
            >
              {item.title}
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
}

export default Dropdown;
