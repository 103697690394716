import "./Timeline.css";
import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";

function Timeline() {
  const axisRef = useRef(null);
  const cardRef = useRef(null);
  const inView = useInView(cardRef, { once: true });
  const cardControls = useAnimation();
  const axisControls = useAnimation();

  useEffect(() => {
    if (inView) {
      cardControls.start("visible");
      axisControls.start("visible");
    }
  }, [inView, cardControls, axisControls]);

  return (
    <div id="timeline">
      <motion.span
        id="axis"
        ref={axisRef}
        variants={{
          hidden: { height: "0%", opacity: 0 },
          visible: { height: "85%", opacity: 1 },
        }}
        initial="hidden"
        animate={axisControls}
        transition={{ duration: 0.8, delay: 0.2 }}
      ></motion.span>
      <motion.div
        className="event"
        ref={cardRef}
        variants={{
          hidden: { opacity: 0, y: -10 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={cardControls}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        <div className="year">
          2022
          <br />-<br />
          2024
        </div>
        <span className="logo">
          <img src="/images/NYU_Seal.svg" alt="NYU Seal"></img>
        </span>
        <div className="txt-box">
          <p className="institute" style={{ color: "#530296" }}>
            New York University
          </p>
          <p className="caption">Master of Science in Computer Science</p>
        </div>
      </motion.div>
      <motion.div
        className="event"
        ref={cardRef}
        variants={{
          hidden: { opacity: 0, y: -10 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={cardControls}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <div className="year">
          2020
          <br />-<br />
          2022
        </div>
        <span className="logo">
          <img src="/images/OSU_Seal.svg" alt="OSU Seal"></img>
        </span>
        <div className="txt-box">
          <p className="institute" style={{ color: "#960202" }}>
            The Ohio State University
          </p>
          <p className="caption">
            Bachelor of Science in Computer Science and Engineering
          </p>
        </div>
      </motion.div>
    </div>
  );
}

export default Timeline;
