import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import { useEffect } from "react";
import MyNav from "./components/MyNav";
import Home from "./pages/Home";
import Hobbies from "./pages/Hobbies";
import Footer from "./components/Footer";

function App() {
  // global smooth scroll
  let location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let element = document.getElementById(location.hash.slice(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <>
      <MyNav />
      <div className="App">
        <Routes>
          <Route index element={<Home />} />
          <Route path="/hobbies" element={<Hobbies />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;
