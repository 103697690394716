import React, { createRef, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Dropdown from "./Dropdown";
import "./MyNav.css";

export const headerRef = createRef(null);

function MyNav() {
  const [menuClick, setMenuClick] = useState(false);
  const [hobbiesClick, setHobbiesClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  // dropdown arrow animation controls
  useEffect(() => {
    const arrow = document.querySelector(".fa-angle-down");
    if (dropdown && !hobbiesClick) {
      arrow.classList.add("rotate");
    } else if (!dropdown || hobbiesClick) {
      arrow.classList.remove("rotate");
    }
  }, [dropdown, hobbiesClick]);

  // open dropdown conditionally
  const openDropdown = () => {
    if (window.innerWidth > 960) {
      setDropdown(true);
    } else {
      setDropdown(false);
    }
  };

  return (
    <nav className="navbar">
      <span id="section-title" ref={headerRef}></span>
      <div className="menu-icon" onClick={() => setMenuClick(!menuClick)}>
        <i className={menuClick ? "fa-solid fa-xmark" : "fa-solid fa-bars"} />
      </div>
      <ul
        className={menuClick ? "nav-menu active" : "nav-menu"}
        onClick={() => setMenuClick(!menuClick)}
      >
        <li className="nav-item">
          <NavLink
            to="/"
            className={({ isActive }) =>
              [isActive ? "active" : "", "nav-link"].join(" ")
            }
          >
            <span>Home</span>
          </NavLink>
        </li>
        <li
          className="nav-item"
          onMouseEnter={openDropdown}
          onMouseLeave={() => setDropdown(false)}
          onClick={() => setHobbiesClick(true)}
          onMouseMove={() => setHobbiesClick(false)}
        >
          <NavLink
            to="/hobbies"
            className={({ isActive }) =>
              [isActive ? "active" : "", "nav-link"].join(" ")
            }
          >
            <span>Hobbies</span>
            <i className="fa-solid fa-angle-down" />
          </NavLink>
          {dropdown && !hobbiesClick && <Dropdown setDropdown={setDropdown} />}
        </li>
      </ul>
    </nav>
  );
}

export default MyNav;
